import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AdminRoute from '../components/AdminRoute';
import firebase from 'gatsby-plugin-firebase';
import styled, { useTheme } from 'styled-components';
import ManageUser from '../components/manage/User';
import { reject, some, sortBy } from 'lodash';
import AddUser from '../components/manage/AddUser';
import WithHeroLayout from '../layouts/WithHeroLayout';
import SectionDescription from '../components/formatting/SectionDescription';
import Section from '../components/formatting/Section';
import Checkbox from '../components/form-elements/Checkbox';
import Button from '../components/form-elements/Button';
import LoadingIcon from '../components/LoadingIcon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Panel = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionPanel = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const AddUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * {
    margin-bottom: 12px;
  }
`;

const CurrentUserCount = styled.span`
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
`;

interface ActionProps {
  disabled: boolean;
}

const Action = styled.button<ActionProps>`
  background: none;
  border: none;
  color: ${props => props.theme.colors.purple};
  font-size: 0.9em;
  font-weight: 500;
  text-decoration: underline;
  font-family: 'Montserrat', sans-serif;

  opacity: ${props => (props.disabled ? '0.5' : '1')};

  &:hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    text-decoration: ${props => (props.disabled ? 'underline' : 'none')};
  }
`;

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 4fr 2fr;
  background-color: ${props => props.theme.colors.lightGray};
  align-items: center;
  padding: 10px 0;
  font-size: 24px;
  column-gap: 16px;
`;

const ColumnTitle = styled.span`
  font-weight: 600;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ManagePage: React.FC = function () {
  const [users, setUsers] = useState<SDEAFirestore.User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<SDEAFirestore.User[]>([]);

  useEffect(() => {
    async function fetchUsers() {
      const { data } = await firebase.functions().httpsCallable('getUsers')();
      setUsers(data);
    }

    fetchUsers();
  }, []);

  const bulkUpdateSelectedUsers = useCallback(
    (roles: string[]) => {
      const emails = selectedUsers.map(user => user.email);
      const data = {
        emails: selectedUsers.map(user => user.email),
        roles,
      };

      async function update() {
        await firebase.functions().httpsCallable('updateUserRoles')(data);

        setUsers(users => [
          ...reject(users, u => emails.indexOf(u.email) !== -1),
          ...selectedUsers.map(user => ({
            ...user,
            roles,
          })),
        ]);
        setSelectedUsers([]);
      }

      update();
    },
    [selectedUsers]
  );

  const handleMakeAdminClicked = useCallback(() => {
    bulkUpdateSelectedUsers(['sdea_member', 'admin']);
  }, [bulkUpdateSelectedUsers]);

  const handleRemoveAdminClicked = useCallback(() => {
    bulkUpdateSelectedUsers(['sdea_member']);
  }, [bulkUpdateSelectedUsers]);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteUserClicked = useCallback(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const emails = selectedUsers.map(user => user.email);
    const result = window.confirm(
      `Are you sure you want to delete the account(s) of:\n\n${emails.join(
        '\n'
      )}`
    );
    if (!result) {
      return;
    }

    async function send() {
      setIsDeleting(true);
      await firebase.functions().httpsCallable('deleteUser')({
        emails,
      });
      setUsers(userList =>
        userList.filter(user => emails.indexOf(user.email) === -1)
      );
      setSelectedUsers([]);
      setIsDeleting(false);
      window.alert(`The ${emails.length} accounts were deleted.`);
    }

    send().catch(console.error);
  }, [selectedUsers]);

  const handleSendResetPasswordClicked = useCallback(() => {
    async function send() {
      await firebase.functions().httpsCallable('resetPassword')({
        emails: selectedUsers.map(user => user.email),
      });
    }

    send().catch(console.error);
  }, [selectedUsers]);

  const handleCheckClicked = useCallback(
    (user: SDEAFirestore.User, isChecked: boolean) => {
      if (isChecked) {
        setSelectedUsers(array => [...array, user]);
      } else {
        setSelectedUsers(array => array.filter(u => u.email !== user.email));
      }
    },
    []
  );

  const handleUserCreated = useCallback((user: SDEAFirestore.User) => {
    setUsers(array => [...array, user]);
  }, []);

  const [isAllSelected, setIsAllSelected] = useState(false);

  useEffect(() => {
    if (!isAllSelected) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users);
    }
  }, [isAllSelected, users]);

  const handlePostRedesignWelcomeEmailClicked = useCallback(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const emails = selectedUsers.map(user => user.email);
    const result = window.confirm(
      `Are you sure you want to send the post-redesign welcome email?\nThis will send the email to:\n\n${emails.join(
        '\n'
      )}`
    );
    if (!result) {
      return;
    }
    async function send() {
      await firebase.functions().httpsCallable('sendWelcomeRedesignEmail')({
        emails,
      });
      window.alert('The post-Redesign Welcome email was sent!');
    }

    send();
  }, [selectedUsers]);

  const theme = useTheme();

  const [isAddUserVisible, setIsAddUserVisible] = useState(false);

  const sortedUsers = useMemo(() => sortBy(users, 'email'), [users]);

  return (
    <AdminRoute>
      <WithHeroLayout pageTitle="Admin Management">
        <Section>
          <SectionDescription>
            Manage new and existing users and set their permissions in this
            dashboard.
          </SectionDescription>
        </Section>
        <Section>
          <Wrapper>
            <Panel>
              <AddUserContainer>
                <Button
                  buttonType={isAddUserVisible ? 'active' : 'primary'}
                  onClick={() => setIsAddUserVisible(visible => !visible)}
                >
                  Add A New User
                </Button>

                {isAddUserVisible && (
                  <AddUser onUserCreated={handleUserCreated} />
                )}
              </AddUserContainer>
              <CurrentUserCount>Current Users: {users.length}</CurrentUserCount>
            </Panel>
            <ActionPanel>
              <Action
                disabled={selectedUsers.length === 0}
                onClick={handleMakeAdminClicked}
              >
                Make ADMIN
              </Action>
              <Action
                disabled={selectedUsers.length === 0}
                onClick={handleRemoveAdminClicked}
              >
                Make Regular Member (Revoke ADMIN)
              </Action>
              {isDeleting ? (
                <LoadingIcon />
              ) : (
                <Action
                  disabled={selectedUsers.length === 0}
                  onClick={handleDeleteUserClicked}
                >
                  Delete User
                </Action>
              )}
              <Action
                disabled={selectedUsers.length === 0}
                onClick={handleSendResetPasswordClicked}
              >
                Send Reset Password Email
              </Action>
              <Action
                disabled={selectedUsers.length === 0}
                onClick={handlePostRedesignWelcomeEmailClicked}
              >
                Send post-Redesign Welcome email
              </Action>
            </ActionPanel>
            <UserContainer>
              <HeaderRow>
                <Checkbox
                  color={theme.colors.purple}
                  checked={isAllSelected}
                  onClick={() => setIsAllSelected(selected => !selected)}
                />
                <ColumnTitle>First Name</ColumnTitle>
                <ColumnTitle>Last Name</ColumnTitle>
                <ColumnTitle>Email</ColumnTitle>
                <ColumnTitle>Roles</ColumnTitle>
              </HeaderRow>
              {sortedUsers.map(user => (
                <ManageUser
                  key={user.email}
                  user={user}
                  onCheckChange={checked => handleCheckClicked(user, checked)}
                  isSelected={some(selectedUsers, u => u.email === user.email)}
                  onFieldChange={(field: string, value: string) => {
                    const selectedUserIndex = selectedUsers.findIndex(
                      u => u.email === user.email
                    );

                    if (selectedUserIndex !== -1) {
                      const newSelectedUsers = [...selectedUsers];
                      newSelectedUsers[selectedUserIndex] = {
                        ...user,
                        [field]: value,
                      };
                      setSelectedUsers(newSelectedUsers);
                    }

                    setUsers(users => [
                      ...users.filter(u => u.email !== user.email),
                      {
                        ...user,
                        [field]: value,
                      },
                    ]);
                  }}
                />
              ))}
            </UserContainer>
          </Wrapper>
        </Section>
      </WithHeroLayout>
    </AdminRoute>
  );
};

export default ManagePage;
