import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import useFirebaseAuth from '../hooks/useFirebaseAuth';

const AdminRoute: React.FC = function (props) {
  const { children } = props;
  const { loading, currentUser } = useFirebaseAuth();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (!loading && currentUser === null) {
      navigate('/login');
    }
  }, [loading, currentUser]);

  useEffect(() => {
    async function checkAdmin() {
      if (currentUser === null) {
        return;
      }
      const { claims } = await currentUser.getIdTokenResult(true);
      setIsAdmin(
        Array.isArray(claims.roles) && claims.roles.indexOf('admin') !== -1
      );
    }
    checkAdmin();
  }, [currentUser]);

  if (loading || !isAdmin) {
    return null;
  }

  return children;
};

export default AdminRoute;
