import React, { FormEvent, useCallback, useState } from 'react';
import styled from 'styled-components';
import firebase from 'gatsby-plugin-firebase';
import Input from '../form-elements/Input';
import Button from '../form-elements/Button';
import LoadingIcon from '../LoadingIcon';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * {
    margin-bottom: 12px;
  }
`;

const InputContainer = styled.div`
  display: grid;
  column-gap: 8px;
  row-gap: 8px;
  grid-auto-flow: column;
  grid-template-areas:
    'a b'
    'c c';

  *:nth-child(1) {
    grid-area: a;
  }

  *:nth-child(2) {
    grid-area: b;
  }

  *:nth-child(3) {
    grid-area: c;
  }
`;

const Error = styled.span``;

interface Props {
  onUserCreated: (user: SDEAFirestore.User) => void;
}

const AddUser: React.FC<Props> = function (props) {
  const { onUserCreated } = props;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmitClicked = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (email.length === 0) {
        return;
      }

      setError(null);

      async function addUser() {
        setLoading(true);
        const { data } = await firebase.functions().httpsCallable('addUser')({
          first_name: firstName,
          last_name: lastName,
          email,
        });

        setLoading(false);
        onUserCreated(data);
        setFirstName('');
        setLastName('');
        setEmail('');
      }

      addUser().catch(setError);
    },
    [email, onUserCreated, firstName, lastName]
  );

  return (
    <Wrapper>
      <InputContainer>
        <Input
          type="text"
          placeholder="First Name"
          onChange={e => setFirstName(e.target.value)}
          value={firstName}
          required
        />
        <Input
          type="text"
          placeholder="Last Name"
          onChange={e => setLastName(e.target.value)}
          value={lastName}
          required
        />
        <Input
          type="text"
          placeholder="Email"
          onChange={e => setEmail(e.target.value)}
          value={email}
          required
        />
      </InputContainer>
      <div>
        {loading ? (
          <LoadingIcon />
        ) : (
          <Button
            buttonType="primary"
            onClick={handleSubmitClicked}
            disabled={loading}
          >
            Add
          </Button>
        )}
        {error && <Error>An error occurred</Error>}
      </div>
    </Wrapper>
  );
};

export default AddUser;
