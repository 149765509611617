import styled from 'styled-components';

const LoadingIcon = styled.img.attrs({
  src: '/icons/loading.svg',
})`
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  animation: rotation 2s;
  animation-iteration-count: infinite;
`;

export default LoadingIcon;
