import React, { useCallback, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Checkbox from '../form-elements/Checkbox';

import firebase from 'gatsby-plugin-firebase';
import LoadingIcon from '../LoadingIcon';
const PencilIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 16px;
  transition: transform 100ms ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`;

const Button = styled.button`
  font-size: 1em;
`;

const Field = styled.div`
  display: flex;
  align-items: center;
`;

const EditWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditableFieldInput = styled.input`
  font-size: 1em;
  font-family: inherit;
`;

interface EditableFieldProps {
  value: string;
  placeholder?: string;
  onEditClick: (e: React.MouseEvent, value: string) => void;
}

const EditableField: React.FC<EditableFieldProps> = function (props) {
  const { value, onEditClick, placeholder } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);

  return (
    <Field>
      {isEditing ? (
        <EditWrapper>
          <EditableFieldInput
            type="text"
            value={editValue}
            onChange={e => setEditValue(e.target.value)}
            placeholder={placeholder ?? 'Empty'}
            size={Math.max(20, value.length)}
          />
          <div>
            <Button
              onClick={e => {
                onEditClick(e, editValue);
                setIsEditing(false);
              }}
            >
              Save
            </Button>
            <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          </div>
        </EditWrapper>
      ) : (
        <>
          {value}
          <PencilIcon
            src="/icons/pencil.svg"
            onClick={() => setIsEditing(!isEditing)}
          />
        </>
      )}
    </Field>
  );
};

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface WrapperProps {
  isSelected?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: grid;
  grid-template-areas: 'checkbox first-name last-name email roles';
  grid-template-columns: 1fr 3fr 3fr 4fr 2fr;
  align-items: center;
  padding: 10px 0;
  font-size: 18px;
  column-gap: 16px;

  background-color: ${props => (props.isSelected ? '#ccc' : 'transparent')};
`;

const Roles = styled.span``;

interface Props {
  user: SDEAFirestore.User;
  onCheckChange: (checked: boolean) => void;
  onFieldChange: (field: string, value: string) => void;
  isSelected: boolean;
}

const User: React.FC<Props> = function (props) {
  const { user, onCheckChange, onFieldChange, isSelected } = props;

  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const handleEditFirstNameClicked = useCallback(
    (_, value: string) => {
      async function update() {
        setLoading(true);
        await firebase.functions().httpsCallable('editUserField')({
          field: 'first_name',
          value,
          userEmail: user.email,
        });
        setLoading(false);
        onFieldChange('first_name', value);
      }
      update();
    },
    [user, onFieldChange]
  );
  const handleEditLastNameClicked = useCallback(
    (_, value: string) => {
      async function update() {
        setLoading(true);
        await firebase.functions().httpsCallable('editUserField')({
          field: 'last_name',
          value,
          userEmail: user.email,
        });
        onFieldChange('last_name', value);
        setLoading(false);
      }
      update();
    },
    [user, onFieldChange]
  );
  const handleEditEmailClicked = useCallback(
    (_, value: string) => {
      async function update() {
        setLoading(true);
        await firebase.functions().httpsCallable('editUserField')({
          field: 'email',
          value,
          userEmail: user.email,
        });
        onFieldChange('email', value);
        setLoading(false);
      }
      update();
    },
    [user, onFieldChange]
  );

  return (
    <Wrapper isSelected={isSelected}>
      <CheckboxWrapper>
        <Checkbox
          color={theme.colors.purple}
          onClick={e => onCheckChange(e.currentTarget.checked)}
          checked={isSelected}
        />
        {loading && <LoadingIcon />}
      </CheckboxWrapper>
      <EditableField
        value={user.first_name}
        onEditClick={handleEditFirstNameClicked}
      />
      <EditableField
        value={user.last_name}
        onEditClick={handleEditLastNameClicked}
      />
      <EditableField value={user.email} onEditClick={handleEditEmailClicked} />
      <Roles>{user.roles.join(', ')}</Roles>
    </Wrapper>
  );
};

export default User;
